<template>
  <div
    ref="marker"
    class="mtkObjectMarker maplibregl-marker"
    @click="handleMarkerClick"
  >
    <span v-if="title" class="tooltip">{{ title }}</span>

    <svg
      v-if="!highlighted"
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5188 22.8C12.5188 22.8 14.9885 18.7887 15.4516 18.1714C17.9214 14.6228 20.0825 11.3829 20.0825 9.06868C20.3912 4.44007 16.9953 1.2 12.5188 1.2C8.1968 1.2 4.80078 4.59426 4.80078 8.91438C4.80078 11.5372 7.11621 14.9315 9.58602 18.4801C9.89465 18.9429 12.5188 22.8 12.5188 22.8ZM12.5188 5.82861C13.7538 5.82861 14.9885 7.06296 14.9885 8.4514C15.1429 9.68575 14.0624 10.92 12.5188 10.92C11.1296 10.92 9.89465 9.84004 10.049 8.4514C10.049 7.06286 11.1295 5.82861 12.5188 5.82861Z"
        fill="var(--color-controls-map-poi_default)"
      />
    </svg>
    <svg
      v-if="highlighted"
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5188 22.8C12.5188 22.8 14.9885 18.7887 15.4516 18.1714C17.9214 14.6228 20.0825 11.3829 20.0825 9.06868C20.3912 4.44007 16.9953 1.2 12.5188 1.2C8.1968 1.2 4.80078 4.59426 4.80078 8.91438C4.80078 11.5372 7.11621 14.9315 9.58602 18.4801C9.89465 18.9429 12.5188 22.8 12.5188 22.8ZM12.5188 5.82861C13.7538 5.82861 14.9885 7.06296 14.9885 8.4514C15.1429 9.68575 14.0624 10.92 12.5188 10.92C11.1296 10.92 9.89465 9.84004 10.049 8.4514C10.049 7.06286 11.1295 5.82861 12.5188 5.82861Z"
        fill="var(--color-controls-map-poi_active)"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { convertBaseCoordsToLatLngLike } from '../../utils/convertBaseCoords';

declare let maplibregl: unknown;

export default defineComponent({
  inject: { mtkMap: { from: 'mtkMap' } },
  props: {
    title: String,
    coords: Object,
    highlighted: Boolean,
  },
  emits: ['marker-click'],
  data() {
    return {
      marker: null,
    };
  },
  watch: {
    coords() {
      this.ensureMarker();
    },
    mtkMap() {
      if (this.marker) {
        this.marker.remove();
        this.marker = null;
      }

      if (this.mtkMap) {
        this.ensureMarker();
      } else {
        this.marker = null;
      }
    },
  },
  mounted() {
    this.ensureMarker();
  },
  beforeUnmount() {
    if (this.marker) {
      this.marker.remove();
      this.marker = null;
    }
  },
  methods: {
    ensureMarker() {
      if (this.mtkMap && this.coords) {
        if (this.marker) {
          this.marker.setLngLat(convertBaseCoordsToLatLngLike(this.coords));
          this.marker.setOffset([0, -24]);
        } else {
          this.marker = new maplibregl.Marker({
            element: this.$refs.marker,
            offset: [0, -24],
            draggable: false,
          })
            .setLngLat(convertBaseCoordsToLatLngLike(this.coords))
            .addTo(this.mtkMap.gl);
        }
      }
    },
    handleMarkerClick(event: unknown) {
      this.$emit('marker-click', event);
    },
  },
});
</script>

<style lang="scss" scoped>
.mtkObjectMarker {
  cursor: pointer;

  .tooltip {
    position: absolute;
    z-index: 2;

    visibility: hidden;

    background: #000;
    border-radius: 5px;
    padding: 5px;

    box-shadow: 0 4px 4px 0 #00000040;

    display: inline-block;

    width: initial;
    height: initial;

    white-space: nowrap;

    left: 30px;
  }

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}
</style>
