<template>
  <ul class="list">
    <ListStandardTitleFilterListItem
      v-for="(item, index) in items"
      :key="index"
      :content="item.display"
      @click="handleRemove(index)"
    />
  </ul>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import type { RawWidgetConfigEventDefFragment } from '../../../../../../gql/fragments/__generated/RawWidgetConfigEventDef';
import type { RawWidgetConfigPoiDefFragment } from '../../../../../../gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { RawWidgetConfigTourDefFragment } from '../../../../../../gql/fragments/__generated/RawWidgetConfigTourDef';
import { BaseTourDifficulty } from '../../../../../../models/BaseTourDifficulty';
import { WhlModuleType } from '../../../../../../models/WhlModuleType';
import type { Nullable } from '../../../../../../models/CustomUtilityTypes';

const { t, d } = useI18n();

const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(widgetConfig);

const whlModuleType = useWhlModuleType();
const searchStore = useSearchStore();

// retrieve configs
const configsObject = computed(() => {
  switch (toValue(whlModuleType)) {
    case WhlModuleType.Event:
      return {
        categories: (
          widgetConfigTypeDef.value as RawWidgetConfigEventDefFragment
        ).categoryFilterCategories,
        criteria: (widgetConfigTypeDef.value as RawWidgetConfigEventDefFragment)
          .criterionFilterCriteria,
      };
    case WhlModuleType.Poi:
      return {
        categories: (widgetConfigTypeDef.value as RawWidgetConfigPoiDefFragment)
          .categoryFilterProductlines,
        criteria: (widgetConfigTypeDef.value as RawWidgetConfigPoiDefFragment)
          .criterionFilterProductlines,
      };
    case WhlModuleType.Tour:
      return {
        categories: (
          widgetConfigTypeDef.value as RawWidgetConfigTourDefFragment
        ).activityFilterTourCategories,
        criteria: (widgetConfigTypeDef.value as RawWidgetConfigTourDefFragment)
          .attributeFilterTourAttributes,
      };
    default:
      return null;
  }
});

const items: ComputedRef<
  Nullable<{ key: string; value?: number; display: string }>[]
> = computed(() => {
  const search = searchStore.state.search
    ? searchStore.state.search.join(' ')
    : null;

  const categories = searchStore.state.categories.map((id) => ({
    key: 'categories',
    value: id,
    display: `${t('components.list.standard.title.filter.list.category')}: ${configsObject.value?.categories?.find((c) => c.id === id)?.i18nName}`,
  }));

  const criteria = searchStore.state.criteria.map((id) => ({
    key: 'criteria',
    value: id,
    display: `${t('components.list.standard.title.filter.list.criteria')}: ${configsObject.value?.criteria?.find((c) => c.id === id)?.i18nName}`,
  }));

  const length = !isEmpty(searchStore.state.length)
    ? {
        key: 'length',
        display: `${t('components.list.standard.title.filter.list.length')}: ${joinNonEmptyStrings(
          searchStore.state.length?.map((val) =>
            formatDistanceToKm(val, 'm', 0)
          ),
          ' - '
        )}`,
      }
    : null;

  const duration = !isEmpty(searchStore.state.duration)
    ? {
        key: 'duration',
        display: `${t('components.list.standard.title.filter.list.duration')}: ${joinNonEmptyStrings(
          searchStore.state.duration?.map((val) => formatDuration(val)),
          ' - '
        )}`,
      }
    : null;

  const difficulties = searchStore.state.difficulties.map((difficulty) => {
    let difficultyString = '';

    switch (difficulty) {
      case BaseTourDifficulty.EASY:
        difficultyString = t(
          'components.list.standard.title.filter.list.difficulty.easy'
        );
        break;
      case BaseTourDifficulty.MEDIUM:
        difficultyString = t(
          'components.list.standard.title.filter.list.difficulty.medium'
        );
        break;
      case BaseTourDifficulty.HARD:
        difficultyString = t(
          'components.list.standard.title.filter.list.difficulty.hard'
        );
        break;
      default:
        difficultyString = difficulty.toString();
    }

    return {
      key: 'difficulties',
      value: difficulty,
      display: `${t('components.list.standard.title.filter.list.difficulty')}: ${difficultyString}`,
    };
  });

  const dateFromFormatted = searchStore.state.dateFrom
    ? d(
        new Date(dayjs(searchStore.state.dateFrom).format('YYYY-MM-DD')),
        'long'
      )
    : '';
  const dateToFormatted = searchStore.state.dateTo
    ? d(new Date(dayjs(searchStore.state.dateTo).format('YYYY-MM-DD')), 'long')
    : '';
  const dateFormatted =
    dateFromFormatted === dateToFormatted
      ? dateFromFormatted
      : joinNonEmptyStrings([dateFromFormatted, dateToFormatted], ' - ');
  const date = dateFormatted
    ? {
        key: 'date',
        value: dateFormatted,
        display: `${t('components.list.standard.title.filter.list.date')}: ${dateFormatted}`,
      }
    : null;

  const daytime = !isEmpty(searchStore.state.daytime)
    ? {
        key: 'daytime',
        display: `${t('components.list.standard.title.filter.list.daytime')}: ${joinNonEmptyStrings(
          searchStore.state.daytime.map((val) =>
            t(`components.list.standard.title.filter.list.daytime.${val}`)
          ),
          ', '
        )}`,
      }
    : null;

  const location = !isEmpty(searchStore.state.locationName)
    ? {
        key: 'location',
        display: `${t(`components.list.standard.title.filter.list.location.default`)}: ${searchStore.state.locationName}`,
      }
    : null;

  const oneOffEvents = !isEmpty(searchStore.state.hasSingleEvent)
    ? {
        key: 'hasSingleEvent',
        display: t('components.list.standard.title.filter.list.oneOffEvents'),
      }
    : null;

  const freeOfChargeEvents = !isEmpty(searchStore.state.onlyFree)
    ? {
        key: 'onlyFree',
        display: t(
          'components.list.standard.title.filter.list.freeOfChargeEvents'
        ),
      }
    : null;

  return [
    search
      ? {
          key: 'search',
          value: search,
          display: t(
            'components.list.standard.title.filter.list.search.display',
            { search }
          ),
        }
      : null,
    ...categories,
    ...criteria,
    duration,
    length,
    ...difficulties,
    location,
    date,
    daytime,
    oneOffEvents,
    freeOfChargeEvents,
  ].filter(Boolean);
});

const handleRemove = (index: number) => {
  const item = items.value[index];
  if (item?.value) {
    searchStore.removeValue(item.key as keyof SearchModel, item.value);
  } else {
    searchStore.removeValue(item?.key as keyof SearchModel);
  }
};
</script>

<style src="./List.scss" scoped lang="scss"></style>
