<template>
  <div v-show="searchStore.hasAnyValue" class="filter">
    <ListStandardTitleFilterHead />
    <ListStandardTitleFilterReset />
    <ListStandardTitleFilterList />
  </div>
</template>

<script lang="ts" setup>
const searchStore = useSearchStore();
</script>

<style src="./Filter.scss" scoped lang="scss"></style>
