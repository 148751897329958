<template>
  <div class="datelistWrap">
    <span class="info">{{
      t('components.teaser.list.text.datelist.moreDates')
    }}</span>

    <ul class="datelist">
      <li v-for="(item, index) in displayedItems" :key="index" class="item">
        <div>{{ item.text }}</div>
      </li>
      <li v-if="additionalCount > 0" class="item">
        <div>...</div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { Datelist } from '../../models';
const { t } = useI18n();

const props = defineProps<{
  content: Datelist;
}>();

const displayedItems = computed(() => {
  return props.content.items.slice(0, 3);
});

const additionalCount = computed(() => {
  return props.content.items.length > 3 ? props.content.items.length - 3 : 0;
});
</script>

<style src="./Datelist.scss" scoped lang="scss"></style>
