<template>
  <div ref="map" class="map">
    <div
      ref="sticky"
      class="sticky"
      :class="{ bottom: bottom }"
      :style="{ top: fixed ? `${-top}px` : '' }"
    >
      <NuxtErrorBoundary @error="() => {}">
        <MtkMap
          ref="mtkMap"
          v-model:hide-smart-scroll-overlay="hideSmartScrollOverlay"
          :center="mapCenter"
          :bounds="bounds"
          @map-init="ensureMapBounds"
        >
          <MtkMapObjectMarker
            v-for="item in content.items"
            :key="item.id"
            :coords="item.coords"
            :highlighted="item.id === activeItemId"
            @marker-click="displayInfoWindowForItem(item.id)"
          />
        </MtkMap>

        <div v-if="activeItem" class="popup">
          <TeaserList :content="activeItem" />
        </div>
      </NuxtErrorBoundary>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BaseCoords } from '../../../../models/BaseCoords';
import type { AllNullable } from '../../../../models/CustomUtilityTypes';
import { convertBaseCoordsToLatLngLike } from '../../../../utils/convertBaseCoords';
import type { List } from '../../Standard/models';
import { useIntersectionObserver, useElementBounding } from '@vueuse/core';

const props = defineProps<{
  content: List;
  externalActiveItemId: number | null;
}>();

const emit = defineEmits(['marker-click']);

const mapCenter = ref<BaseCoords | undefined>(undefined);
const bounds = ref<{ lat: number; lon: number }[] | undefined>(undefined);

const map = ref<HTMLElement | null>(null);
const sticky = ref<HTMLElement | null>(null);

const fixed = ref<boolean>(false);
const bottom = ref<number>(0);

const ensureMapBounds = (): void => {
  if (props.content?.items?.length > 0) {
    const ne: AllNullable<BaseCoords> = { latitude: null, longitude: null };
    const sw: AllNullable<BaseCoords> = { latitude: null, longitude: null };

    props.content.items.forEach((item) => {
      if (item.coords) {
        if (!ne.latitude || item.coords.latitude > ne.latitude) {
          ne.latitude = item.coords.latitude;
        }
        if (!ne.longitude || item.coords.longitude > ne.longitude) {
          ne.longitude = item.coords.longitude;
        }
        if (!sw.latitude || item.coords.latitude < sw.latitude) {
          sw.latitude = item.coords.latitude;
        }
        if (!sw.longitude || item.coords.longitude < sw.longitude) {
          sw.longitude = item.coords.longitude;
        }
      }
    });

    bounds.value = [
      convertBaseCoordsToLatLngLike(sw as BaseCoords),
      convertBaseCoordsToLatLngLike(ne as BaseCoords),
    ];
  }
};

/* this observer is for the upper edge of map container */
useIntersectionObserver(
  map,
  ([entry]) => {
    /* if the top of the container is leaving viewport from the top */
    if (
      entry.boundingClientRect.bottom > sticky.value.clientHeight &&
      entry.boundingClientRect.top < 0
    ) {
      fixed.value = true;
    } else {
      fixed.value = false;
    }
  },
  { rootMargin: '0px 0px -100% 0px' }
);

/* this observer is for the lower edge of map container */
useIntersectionObserver(
  map,
  ([entry]) => {
    /* if the bottom of the container is entering viewport from the bottom */
    if (entry.boundingClientRect.bottom < sticky.value.clientHeight) {
      fixed.value = false;
      bottom.value = true;
    } else if (entry.boundingClientRect.top < 0) {
      fixed.value = true;
      bottom.value = false;
    }
  },
  { rootMargin: '-100% 0px 0px 0px' }
);

const { top } = useElementBounding(map);

const activeItemId = ref<number | null>(null);
watch(
  () => props.content?.items,
  () => {
    ensureMapBounds();
  },
  { immediate: true }
);

// watch for change from outside list (desktop)
watch(
  () => props.externalActiveItemId,
  (newValue: number | null) => {
    activeItemId.value = newValue;

    const item = props.content.items.find((item) => item.id === newValue);
    if (item?.coords) {
      mapCenter.value = item.coords;
    } else {
      mapCenter.value = undefined;
    }
  },
  { immediate: true }
);

const hideSmartScrollOverlay = ref(false);
const displayInfoWindowForItem = (itemId: number): void => {
  activeItemId.value = itemId;
  if (activeItemId.value) {
    // prevent display of smart scroll modal in map
    // reset to false after 3 seconds in MtkMap component
    hideSmartScrollOverlay.value = true;
    // emit event to parent component (which is active on desktop and handles scrolling of list)

    emit('marker-click', itemId);
  }
};

const activeItem = computed(() => {
  const currentId = toValue(activeItemId);
  return props.content.items.find((item) => item.id === currentId);
});
</script>

<!-- call this component MapWrap, because Map is not usable -->
<style src="./Map.scss" scoped lang="scss"></style>
