<template>
  <div v-if="showMap || widgetConfig?.quickAccessList" class="switch">
    <button v-if="showMap" class="button">
      <ButtonCTA
        variant="secondary"
        :content="showMapButtonContent"
        @click="emit('switch')"
      />
    </button>

    <NuxtLink v-if="widgetConfig?.quickAccessList" :to="bookmarksLink">
      <ButtonCounter
        variant="secondary"
        :content="{
          icon: 'ion:heart-outline',
          text: '',
          count: globalStore.state.bookmarksCount,
        }"
      />
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const widgetConfig = await useWidgetConfig();
const localePath = useLocalePath();
const globalStore = useGlobalStore();

const props = defineProps<{
  mapOpen: boolean;
  showMap: boolean;
}>();

const emit = defineEmits(['switch']);

const showMapButtonContent = computed(() => {
  return props.mapOpen
    ? {
        icon: 'ion:list',
        text: t('components.list.map.switch.showlist'),
      }
    : {
        icon: 'ion:map',
        text: t('components.list.map.switch.showmap'),
      };
});

const bookmarksLink = localePath(prefixLinkPath('/bookmarks', widgetConfig));
</script>

<style src="./Switch.scss" scoped lang="scss"></style>
