<template>
  <article class="teaserList" :class="{ highlight: highlight }">
    <div class="layout">
      <TeaserListFig v-if="fig" :content="fig" />

      <TeaserListText :content="content" />

      <TeaserListLike
        v-if="widgetConfig?.quickAccessList && isLocalStorageAvailable()"
        :model-value="isLiked"
        @click="toggleLike"
      />
    </div>
  </article>
</template>

<script lang="ts" setup>
import isLocalStorageAvailable from '../../../utils/isLocalStorageAvailable';
import type { TeaserList } from './models';

const widgetConfig = await useWidgetConfig();
const storage = useLocalStorage<string[]>('bookmarks', []);

const props = withDefaults(
  defineProps<{
    content: TeaserList;
    highlight?: boolean;
  }>(),
  { highlight: false }
);

const fig = computed(() => props.content.fig);
const id = computed(() => props.content.id);
const moduleType = computed(() => props.content.moduleType);

const itemKey = computed(() => `${moduleType.value}_${id.value}`);

const isLiked = computed(() => {
  if (!moduleType.value || !id.value) return false;

  return storage.value.includes(toValue(itemKey));
});

const toggleLike = () => {
  if (!moduleType.value || !id.value) return;

  if (isLiked.value) {
    useGlobalStore().removeBookmark(toValue(itemKey));
  } else {
    useGlobalStore().addBookmark(toValue(itemKey));
  }
};
</script>

<style src="./List.scss" scoped lang="scss"></style>
