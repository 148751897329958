<template>
  <div class="wrap">
    <TeaserListSkeleton
      v-if="content.loading"
      :length="content.items.length"
      class="skeleton"
    />

    <ul class="tile" :class="{ loading: content.loading }">
      <li v-for="item in items" class="item">
        <TeaserList :content="item" />
      </li>
    </ul>

    <ListFooter v-if="showFooter" :class="{ loading: content.loading }" />
  </div>
</template>

<script lang="ts" setup>
import type { List } from '../models';

const props = defineProps<{
  content: List;
  showFooter: boolean;
}>();

const items = computed(() => props.content.items);
</script>

<style src="./Tile.scss" scoped lang="scss"></style>
