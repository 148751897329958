<template>
  <link
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/mtk.css"
  />
  <link
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/ui.css"
  />
  <link
    rel="stylesheet"
    href="https://static.maptoolkit.net/mtk/v10.1.10/elevationprofile.css"
  />

  <div class="imxMap">
    <div ref="mtkMapContainer" class="container" />

    <div class="hidden">
      <slot />
    </div>

    <slot name="below" />
  </div>
</template>

<script setup lang="ts">
import {
  onBeforeUnmount,
  provide,
  ref,
  shallowRef,
  watch,
  onMounted,
} from 'vue';
import { MAPTOOLKIT_APIKEY } from '../../constants.config';
import type { BaseCoords } from '../../models/BaseCoords';
import { convertBaseCoordsToLatLngLike } from '../../utils/convertBaseCoords';
import { ScriptLoader } from './script-loader';

declare let MTK: unknown;
declare let maplibregl: unknown;

const DEFAULT_MAP_ZOOM_LEVEL = 14;

const { locale } = useI18n();

const config = useWhlInstanceConfig();

const hideSmartScrollOverlay = defineModel<boolean>('hideSmartScrollOverlay', {
  default: false,
});

const props = defineProps<{
  center?: BaseCoords;
  zoom?: number;
  bounds?: unknown; // as long as mtk does not provide types we have to deal with unknown values here
  enableFullscreen?: boolean;
}>();

const emit = defineEmits(['mapInit']);

const mtkMapContainer = ref<unknown>(null);
const mtkLoaded = ref<boolean>(false);
const mtkMap = shallowRef<unknown>(null);
const smartScrollOverlay = ref<HTMLElement | null>(null);

let mutationObserver: MutationObserver | null = null;

const redrawMapBus = useEventBus<never>('redrawMap');

provide('mtkMap', mtkMap);
provide('mtkMapContainer', mtkMapContainer);

// load mtk only once
if (typeof MTK === 'undefined') {
  new ScriptLoader('https://static.maptoolkit.net/mtk/v10.1.10/mtk.js')
    .load()
    .then(() =>
      new ScriptLoader(
        'https://static.maptoolkit.net/mtk/v10.1.10/elevationprofile.js'
      ).load()
    )
    .then(() => {
      mtkLoaded.value = true;
    });
} else {
  mtkLoaded.value = true;
}

const redrawMap = () => {
  mtkMap.value?.gl.resize();
};
redrawMapBus.on(redrawMap);

const initMap = () => {
  if (mtkLoaded.value && mtkMapContainer.value) {
    const initialCenter: BaseCoords =
      props.center ?? config.value.map.defaultCoords;
    const initialZoom =
      props.zoom ?? config.value.map.defaultZoom ?? DEFAULT_MAP_ZOOM_LEVEL;

    const controls = [
      [
        new maplibregl.NavigationControl({
          showCompass: false,
          visualizePitch: false,
        }),
        'top-right',
      ],
    ];
    if (config.value.map.weatherControls) {
      controls.push([new MTK.WeatherControl(), 'top-left']);
    }
    if (props.enableFullscreen) {
      controls.push([new maplibregl.FullscreenControl(), 'top-right']);
    }

    MTK.language = toValue(locale);
    MTK.init({ apiKey: MAPTOOLKIT_APIKEY }).createMap(
      mtkMapContainer.value,
      {
        map: {
          location: {
            center: convertBaseCoordsToLatLngLike(initialCenter),
            zoom: initialZoom,
          },
          controls: controls,
          mapType: 'toursprung-terrain',
          options: {
            dragRotate: false,
            pitchWithRotate: false,
          },
          smartScroll: true,
        },
      },
      (map: unknown) => {
        map.gl.scrollZoom.disable();
        map.gl.setZoom(initialZoom);
        map.gl.setCenter(convertBaseCoordsToLatLngLike(initialCenter));

        mtkMap.value = map;
        emit('mapInit');
      }
    );
  }

  /* trigger resize map, as map is not rendered correctly in fullscreen mode on Edge/Chrome */
  mtkMapContainer.value.addEventListener('fullscreenchange', redrawMap);
  mtkMapContainer.value.addEventListener('visibilitychange', redrawMap);
};

watch(mtkLoaded, initMap);
watch(mtkMapContainer, initMap);
watch(
  () => props.center,
  (center) => {
    if (center && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.flyTo({ center: convertBaseCoordsToLatLngLike(center) });
    }
  }
);
watch(
  () => props.zoom,
  (zoom) => {
    if (zoom && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.setZoom(zoom);
    }
  }
);
watch(
  () => props.bounds,
  (bounds) => {
    if (bounds && mtkMap.value) {
      redrawMap();
      mtkMap.value.gl.fitBounds(bounds, { padding: 50 });
    }
  }
);

let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
watchEffect(() => {
  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = undefined;
  if (smartScrollOverlay.value) {
    if (hideSmartScrollOverlay.value) {
      smartScrollOverlay.value.style.visibility = 'hidden';
      hideSmartScrollOverlay.value = false;
    } else {
      timeoutId = setTimeout(() => {
        if (smartScrollOverlay.value) {
          smartScrollOverlay.value.style.visibility = 'visible';
        }
      }, 3000);
    }
  }
});

onBeforeUnmount(() => {
  if (mtkMap.value) {
    mtkMap.value.gl.remove();
    mtkMap.value = undefined;
  }

  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});

onMounted(() => {
  mutationObserver = new MutationObserver(() => {
    checkSmartScrollVisibility();
  });

  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });

  checkSmartScrollVisibility();
});

const checkSmartScrollVisibility = () => {
  const targetElement = document.querySelector('.mtk-map-smartscroll');

  if (targetElement) {
    smartScrollOverlay.value = targetElement;
  } else {
    smartScrollOverlay.value = null;
  }
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

.imxMap {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.imxMap .container {
  width: 100%;
  flex: 100%;
  background: #ccdfe3;

  @include containerSMmax {
    z-index: 0;
  }
}
</style>
