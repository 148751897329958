<template>
  <div class="backlink">
    <BaseIcon class="icon" name="ion:arrow-back" />
    <BaseWhlLink class="text" :to="to">{{ text }}</BaseWhlLink>
  </div>
</template>

<script lang="ts" setup>
import type { HeroBacklink } from '../../models';
const { t } = useI18n();

const props = defineProps<{
  backlink?: HeroBacklink;
}>();

const to = computed(() => props.backlink?.to || '/');
const text = computed(() => props.backlink?.text || t('common.backtolist'));
</script>

<style src="./Backlink.scss" scoped lang="scss"></style>
