<template>
  <div>
    <!-- Error while fetching -->
    <div v-if="error">
      {{ error }}
    </div>

    <!-- Success -->
    <template v-else>
      <span class="global-placeholder-el-medium"></span>
      <HeroTextBacklink
        v-if="previousWhlPageType === WhlPageType.Overview"
        class="global-grid"
        to="/"
      />
      <span class="global-placeholder-el-medium"></span>
      <ListStandardList
        :total-records="totalRecords"
        :content="listContent"
        :allowed-list-types="allowedListTypes"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { List } from '../../../components/List/Standard/models';
import type { TeaserList } from '../../../components/Teaser/List/models';
import buildEventBaseFilter from '../../../composables/buildEventBaseFilter';
import type { RawEventListItemFragment } from '../../../gql/fragments/__generated/RawEventListItem';
import type { RawWidgetConfigEventDefFragment } from '../../../gql/fragments/__generated/RawWidgetConfigEventDef';
import { WhlModuleType } from '../../../models/WhlModuleType';
import { WhlPageType } from '../../../models/WhlPageType';
const previousWhlPageTypes = useSessionStorage('previousWhlPageTypes', [])
  .value as WhlPageType[];
const previousWhlPageType = Object.values(WhlPageType).find(
  (type) => type === previousWhlPageTypes[previousWhlPageTypes.length - 1]
);

definePageMeta({
  whlModuleType: WhlModuleType.Event,
  whlPageType: WhlPageType.List,
  middleware: ['whl-module-type-tracker', 'whl-page-type-tracker'],
});

const { t, d } = useI18n();

const widgetConfig = await useWidgetConfig();

ensureWhlModuleIsEnabled(widgetConfig, WhlModuleType.Event);
const widgetTypeConfig: Ref<RawWidgetConfigEventDefFragment> =
  useWidgetTypeConfig(
    widgetConfig
  ) as ComputedRef<RawWidgetConfigEventDefFragment>;

const searchStore = useSearchStore();
const globalStore = useGlobalStore();
globalStore.setListType(toValue(widgetTypeConfig));

useWhlSeoMeta({
  title: t('event.search.title'),
});

searchStore.readStateFromRoute();

const listType = computed(() =>
  globalStore.state.listType === 'gallery' ? 'tile' : 'default'
);
const pageSize = computed(() => globalStore.state.pageSize);

const baseFilter = buildEventBaseFilter(widgetConfig);
const { eventUserFilter: userFilter, fulltextSearchQuery } =
  storeToRefs(searchStore);

// make use of fetch composables, where refs can be passed (and changes to the ref´s values will trigger a new fetch)
const {
  fetching,
  error,
  totalRecords,
  events: items,
} = await fetchEvents(
  widgetConfig,
  userFilter,
  baseFilter,
  fulltextSearchQuery,
  false,
  pageSize
);

const listContent = computed(
  (): List => ({
    showTitle: widgetTypeConfig.value?.showSearchFilter ?? true,
    title: t('event.search.results.title', totalRecords.value),
    loading: toValue(fetching),
    initialType: listType.value,
    showMap: widgetTypeConfig.value.showMapInList ?? false,
    items:
      items.value?.map((event: RawEventListItemFragment): TeaserList => {
        const { nextEventDateAndTimeConsideringDate, allEventDatesLinkList } =
          transformEventDateData(event, { t, d }, 'medium');

        const infoTagItems = [];
        if (
          !isEmpty(nextEventDateAndTimeConsideringDate.value?.date) &&
          !isEmpty(nextEventDateAndTimeConsideringDate.value?.time)
        ) {
          infoTagItems.push(nextEventDateAndTimeConsideringDate.value!.date!);
          infoTagItems.push(nextEventDateAndTimeConsideringDate.value!.time!);
        }
        if (!isEmpty(event.contact?.contact1?.contactName)) {
          infoTagItems.push({
            icon: 'ion:location-sharp',
            text: event.contact!.contact1!.contactName!,
          });
        }

        const { toBaseImage: fig } = mapImxPlatformMedia(event.image);

        return {
          id: event.id ?? 0,
          moduleType: event.__typename as WhlModuleType,
          fig: fig.value ?? undefined,
          title: event.title ?? '',
          to: toValue(buildLinkToDetailsPage(event.permaLink)),
          infoTags: {
            items: infoTagItems,
          },
          datelist: !isEmpty(allEventDatesLinkList.value)
            ? allEventDatesLinkList.value!
            : null,
          coords: event.geoInfo?.coordinates
            ? {
                longitude: event.geoInfo.coordinates.longitude ?? 0,
                latitude: event.geoInfo.coordinates.latitude ?? 0,
              }
            : undefined,
        } satisfies TeaserList;
      }) ?? [],
  })
);

const allowedListTypes = computed(() => {
  return widgetTypeConfig.value?.viewTypes?.map((_) => _.name) ?? [];
});
</script>
