<template>
  <ul class="default">
    <li
      v-for="item in items"
      :id="'standard-default-list-item-id-' + item.id"
      :key="item.id"
      class="item"
      @mouseover="hightlightItem(item.id)"
      @mouseout="unhighlightItem(item.id)"
    >
      <TeaserList :content="item" />
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { List } from '../models';

const props = defineProps<{
  content: List;
  activeItemId: number | null;
}>();

const emit = defineEmits(['item-mouseover', 'item-mouseout']);

const items = computed(() => props.content.items);

const hightlightItem = (itemId: number): void => {
  emit('item-mouseover', itemId);
};

const unhighlightItem = (itemId: number): void => {
  emit('item-mouseout', itemId);
};

watch(
  () => props.activeItemId,
  (itemId: number | null) => {
    if (itemId) {
      const root = getRootDomElement();

      const element = root.getElementById(
        'standard-default-list-item-id-' + itemId
      );
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
);
</script>

<style src="./Default.scss" scoped lang="scss"></style>
