<template>
  <div class="text">
    <TextStyler v-if="content.title">
      <h4 class="title">{{ content.title }}</h4>
    </TextStyler>

    <BaseWhlLink v-if="content.to" :to="content.to" class="link"
      >{{ t('common.more') }}
    </BaseWhlLink>

    <TeaserListTextInfoTags
      v-if="content.infoTags"
      :content="content.infoTags"
    />

    <TeaserListTextDatelist
      v-if="content.datelist"
      :content="content.datelist"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Text } from '../models';

const { t } = useI18n();

defineProps<{
  content: Text;
}>();
</script>

<style src="./Text.scss" scoped lang="scss"></style>
